<template>
	<b-modal
		ref="provider-bank-account-tracking"
		title="Tracking de cuentas bancarias"
		no-close-on-backdrop
		centered
		@hidden="handleHidden"
		size="lg"
		hide-footer
	>
		<div>
			<b-table-simple responsive sticky-header="60vh" no-border-collapse>
				<b-thead class="sticky-header" style="white-space: nowrap">
					<b-th class="text-center">Número de cuenta bancaria</b-th>
					<b-th class="text-center">Creado por</b-th>
				</b-thead>
				<b-tbody>
					<template v-if="bankAccounts.length > 0">
						<b-tr v-for="(bankAccout, index) in bankAccounts" :key="index">
							<b-td class="text-center">
								<span>{{ bankAccout.bank_account_number }}</span>
							</b-td>

							<b-td class="text-center">
								<div>{{ bankAccout.created_by }}</div>
								<div>{{ bankAccout.created_at | myGlobalDayShort }}</div>
							</b-td>
						</b-tr>
					</template>
					<b-tr v-if="bankAccounts.length === 0">
						<b-td colspan="10" class="text-center" style="height: 80px">No hay registros para mostrar</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
		</div>
	</b-modal>
</template>

<script>
import modalMixin from "@/mixins/modal"
import ProviderService from "@/views/brain/administrative/views/providers/services/providers.service.ts"

export default {
	mixins: [modalMixin],
	props: {
		provider_id: Number,
	},
	data() {
		return {
			isLoading: false,
			specimens: [],
			bankAccounts: [],
		}
	},
	async created() {
		await this.getBankAccoutTracking()
	},
	computed: {
		categoryOpts() {
			return this.S_OPTIONS_MACHO
		},
	},
	methods: {
		async getBankAccoutTracking() {
			this.isPreloading()

			try {
				const { data } = await ProviderService.getBankAccoutTracking(this.provider_id)
				this.bankAccounts = data
			} catch (error) {
				throw error
			} finally {
				this.isPreloading(false)
			}
		},
		handleHidden() {
			this.isLoading = false
			this.$emit("closing")
		},
	},
	async mounted() {
		this.toggleModal("provider-bank-account-tracking")
	},
}
</script>

<style></style>
