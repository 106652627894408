export default [
	{
		key: "company_name",
		label: "Razon social",
		visible: true,
		thClass: "text-center",
	},
	{
		key: "phone_number",
		label: "Telefono",
		visible: true,
		thClass: "text-center",
	},
	{
		key: "email",
		label: "Email",
		visible: true,
		thClass: "text-center",
	},
	{
		key: "country",
		label: "Pais",
		visible: true,
		thClass: "text-center",
	},
	{
		key: "address",
		label: "Direccion",
		visible: true,
		thClass: "text-center",
	},
	// {
	// 	key: "created_by",
	// 	label: "Creado por",
	// 	visible: true,
	// 	thClass: "text-center",
	// },
	{
		key: "actions",
		label: "Acciones",
		thClass: "text-center",
		visible: true,
	},
]
