import axios from "@/axios"
const url = "/api/brain/warehouse/providers/"

class ProviderService {
	async getProviders(params) {
		try {
			const data = await axios.get(`${url}`, { params })
			return data.data
		} catch (error) {
			throw error
		}
	}


	async getProvidersSelect() {
		try {
			const data = await axios.get(`${url}select`,  )
			return data.data
		} catch (error) {
			throw error
		}
	}


	async addProvider(body) {
		try {
			const data = await axios.post(url + "save", body)
			return data
		} catch (error) {
			throw error
		}
	}

	async updateProvider(id, body) {
		try {
			const data = await axios.post(url + `${id}/` + "update", body)
			return data
		} catch (error) {
			throw error
		}
	}

	async deleteProvider(id) {
		try {
			const data = await axios.delete(url + `${id}/` + "delete")
			return data
		} catch (error) {
			throw error
		}
	}
	async getBankAccoutTracking(provider_id){
		try {
			const {data} = await axios.get(`${url}bank-account-tracking/${provider_id}`)
			return data
		} catch (error) {
			throw error
		}
	}
	async getInterbankAccoutTracking(provider_id){
		try {
			const {data} = await axios.get(`${url}interbank-account-tracking/${provider_id}`)
			return data
		} catch (error) {
			throw error
		}
	}
}

export default new ProviderService()
